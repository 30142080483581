import React, { useCallback, useState } from "react";
import { ConfirmDialog } from "components/common/confirm/ConfirmDialog";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface ConfirmOptions {
    title?: string;
    message?: string;
    hideCancelButton?: boolean;
    content?: React.ReactNode;
    confirmButtonText?: string;
}

export type ConfirmCallback = (options?: ConfirmOptions) => Promise<boolean>;
type ResolveFunction = (value: boolean | PromiseLike<boolean>) => void;

export const ConfirmContext = React.createContext<ConfirmCallback | undefined>(
    undefined,
);

export interface ConfirmProviderProps extends React.PropsWithChildren {}

export const ConfirmProvider = ({ children }: ConfirmProviderProps) => {
    const [options, setOptions] = useState<ConfirmOptions>({});
    const [resolveFuncList, setResolveFuncList] = useState<
        [ResolveFunction] | []
    >([]);
    const resolveFunc = resolveFuncList[0];
    const { t } = useTranslation();

    const confirm = (o: ConfirmOptions = {}) => {
        return new Promise<boolean>((resolve) => {
            setOptions({
                title: o.title,
                message: o.message,
                hideCancelButton: o.hideCancelButton,
                content: o.content,
                confirmButtonText: o.confirmButtonText,
            });
            setResolveFuncList([resolve]);
        });
    };

    const closeDialog = useCallback(() => {
        setResolveFuncList([]);
    }, [setResolveFuncList]);

    const onCancel = useCallback(() => {
        resolveFunc?.(false);
        closeDialog();
    }, [resolveFunc, closeDialog]);

    const onConfirm = useCallback(() => {
        resolveFunc?.(true);
        closeDialog();
    }, [resolveFunc, closeDialog]);

    return (
        <>
            <ConfirmContext.Provider value={confirm}>
                {children}
            </ConfirmContext.Provider>
            <ConfirmDialog
                onConfirm={onConfirm}
                open={resolveFunc !== undefined}
                title={options.title ?? t("are_you_sure")}
                onClose={onCancel}
                hideCancelButton={options.hideCancelButton ?? false}
                confirmButtonText={options.confirmButtonText ?? t("continue")}
            >
                <Typography>{options.message ?? ""}</Typography>
                {options.content}
            </ConfirmDialog>
        </>
    );
};
