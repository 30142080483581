import React from "react";
import { DialogContent, DialogProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { NorreButton } from "components/common/NorreButton";

export interface ConfirmDialogProps extends DialogProps {
    title: string;
    onConfirm: () => void;
    onCancel?: () => void;
    hideCancelButton?: boolean;
    confirmButtonText: string;
}

export const ConfirmDialog = ({
    title,
    onConfirm,
    onCancel,
    onClose,
    children,
    hideCancelButton = false,
    confirmButtonText,
    ...other
}: Omit<ConfirmDialogProps, "maxWidth" | "fullWidth" | "fullScreen">) => {
    const { t } = useTranslation();
    return (
        <Dialog maxWidth={"xs"} fullWidth={true} onClose={onClose} {...other}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <NorreButton
                    onClick={(e) => {
                        e.preventDefault();
                        onConfirm();
                    }}
                >
                    {confirmButtonText}
                </NorreButton>
                {hideCancelButton === true ? (
                    <></>
                ) : (
                    <NorreButton
                        color={"secondary"}
                        onClick={(e) => {
                            e.preventDefault();
                            if (onCancel) {
                                onCancel();
                            } else if (onClose) {
                                onClose(e, "escapeKeyDown");
                            }
                        }}
                    >
                        {t("cancel")}
                    </NorreButton>
                )}
            </DialogActions>
        </Dialog>
    );
};
